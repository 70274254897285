<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">Изменение Санкции</p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <div v-if="managers" class="pb-2" :class="mode ? 'text__day2' : 'text__night2'">
            <b>Ma'sul Xodimlar: </b>
            <span v-for="manager in managers" :key="manager.id">{{manager.name}} {{manager.last_name}}, </span>
          </div>
          <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="staff_id">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >Сотрудники</span
                  >
                  <select-staff
                    :id="form.staff_id"
                    v-model="form.staff_id"
                  ></select-staff>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="penatly_reason_id">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >Причина</span
                  >
                  <select-penatly-reason
                    :type="'sanction'"
                    :id="form.reason_id"
                    v-model="form.reason_id"
                  ></select-penatly-reason>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="amount">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >Сумма санкции</span
                  >
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    placeholder="Сумма санкции"
                    :inputValue="form.amount"
                    v-model="form.amount"
                    :type="'number'"
                    size="medium"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item prop="name">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >Название санкции</span
                  >
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    placeholder="Название санкции"
                    :inputValue="form.name"
                    v-model="form.name"                   
                    size="medium"
                  ></crm-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.datas") }}
                  </span>
                  <el-form-item prop="penalty_date">
                    <crm-date-picker
                      v-model="form.penalty_date"
                      :date="form.penalty_date"
                      :size="'medium'"
                      :format="'yyyy-MM-dd'"
                      :value-format="'yyyy-MM-dd'"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                    </crm-date-picker>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.comment") }}</span>
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    :size="'medium'"
                    :placeholder="$t('message.comment')"
                    :inputValue="form.comment"                    
                    v-model="form.comment"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <div id="app" class="my-upload">
                   <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-3">
                      {{ $t("message.file") }}
                    </span>
                    <el-upload
                    class="upload-demo"
                    action="/"
                    :on-change="createImageList"
                    :before-remove="beforeRemove"
                    multiple
                    :limit="5"
                    :on-exceed="handleExceed"
                    :on-remove="handleRemove"
                    :auto-upload="false"
                    :file-list="fileList">
                    <el-button size="small" type="primary">Upload</el-button>
                    <!-- <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div> -->
                  </el-upload>
                  <ul class="el-upload-list el-upload-list--text">
                    <li tabindex="0" class="el-upload-list__item is-ready active" v-for="file in form.all_files" :key="file.id">
                      <a :href="baseUrl+file.url" target="_blank" class="el-upload-list__item-name"><i class="el-icon-document"></i>{{file.name}}
                            </a>
                          <label class="el-upload-list__item-status-label" >
                            <i class="el-icon-upload-success el-icon-circle-check"></i>
                          </label>
                          <i class="el-icon-close" @click="deleteFile(file.id)"></i>
                    </li>
                  </ul>
                </div>
            </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import selectStaff from "@/components/filters/inventory/select-staff";
import selectPenatlyReason from "@/components/filters/inventory/select-penatly-reason";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import show from "@/utils/mixins/show";
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawer,show],
  name: "PenaltyController",
  components: { selectStaff, selectPenatlyReason },
  data() {
    return {
      managers:null,
      loadingButton:false,
      form: {
        loadCard: false,
        amount:0,
        files:'',
      },
      penalty_reason:null,
      fileList: [],
      fileListAll:[],
      fileListTwo: [],
      baseUrl: process.env.VUE_APP_URL.substring(
            0,
            process.env.VUE_APP_URL.length - 3
          ),
    };
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      rules: "penalty/rules",
      model: "penalty/model",
      columns: "penalty/columns",
      penatlyReason: "penaltyReasons/model",
      staffSalary: "penaltyReasons/staff_salary",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      getPenatlyReason: "penaltyReasons/show",
      getStaffSalary: "penaltyReasons/gatStaffSalary",
      save: "penalty/update",
      show: "penalty/show",
      removeFile: "penalty/removeFile",
    }),
        fetchData() {
            if (!this.loadingData) {
              this.loadingData = true;
              console.log(this.selectedItem.id);
              this.show(this.selectedItem.id)
                .then((res) => {
                  this.form = JSON.parse(JSON.stringify(res.data.result.data.penalty));
                  this.managers = this.form.reason.managers
                  if (this.form.all_files) {
                    this.form.all_files.forEach(element => {
                      this.fileListAll.push({
                      name: element.url,
                      url: this.baseUrl + element.url,
                    });
                    });
                   
                  }
                  this.loadingData = false;
                })
                .catch((err) => {
                  this.loadingData = false;
                });
            }
          },
        afterOpen() {
          this.fileList = [];
          this.fileListTwo = [];
          this.form.files ="";
          this.fetchData();
         
        },
    // afterOpen() {
    //   this.form = JSON.parse(JSON.stringify(this.selectedItem));   
    
    // },
    createImageList(file) {
      this.form.files = (file.raw);
      this.fileListTwo.push(this.form.files);
      console.log(this.fileListTwo);

    },
    // afterOpen() {
      
    // },
    onFileChange(e) {
          var files = e.target.files || e.dataTransfer.files;
          if (!files.length) return;
          this.createImage(files);
        },
        createImage(file) {
          var files = new File();
          var reader = new FileReader();
          var vm = this;

          reader.onload = (e) => {
            vm.files = e.target.result;
          };
          reader.readAsDataURL(file);
        },
      handleRemove(file, fileList) {
        console.log(file)
        var index = this.fileListTwo.indexOf(file.raw); // get index if value found otherwise -1

        if (index > -1) { //if found
          this.fileListTwo.splice(index, 1);
        }
        console.log(this.fileListTwo);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
      },
    getReason() {
      if (!this.loadCard) {
        this.loadCard = true;
         this.getPenatlyReason(this.form.reason_id)
          .then((res) => {
            this.penalty_reason = res.data.result.data.penalty_reason;
            this.managers = res.data.result.data.penalty_reason.managers
            if (this.penalty_reason) {
              this.calculateSalary();              
            }
            // this.loadCard = false;
          })
          .catch((err) => {
            this.loadCard = false;
            this.$alert(err);
          });
      }
    },
     getSalary() {
      if (this.form.staff_id) {
         this.getStaffSalary(this.form.staff_id)
          .then((res) => {
            if (this.staffSalary) {
              this.calculateSalary();              
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

   calculateSalary() {
      if (this.staffSalary && this.penalty_reason) {
          if (this.penalty_reason.penalty_type=="procent") {
            this.form.amount = (this.staffSalary*this.penalty_reason.value)/100;
          }
          else{
            this.form.amount = (this.penalty_reason.value);
          }
      }
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.files = this.fileListTwo;
          let formData = new FormData();
            for( var i = 0; i < this.form.files.length; i++ ){
                let file = this.form.files[i];
                formData.append('files[' + i + ']', file);
            }
            for (const key in this.form) {
              formData.append(key, this.form[key]);
            }
            formData["id"] = this.form.id;
            formData["staff_id"] = this.form.staff_id;        
          this.save(formData)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 201) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    deleteFile(id){
      var data ={
        'id':id,
        'penalty_id':this.form.id,
      };
      this.removeFile(data).then((res) => {
          this.form.all_files = res.data.result.data.files;
      });
      // console.log(name);
    }
  },
};
</script>
<style lang="scss">
.active{
  .el-upload-list__item-status-label{
    display: inline-block !important;
  }
}
.el-upload-list__item:hover{
  .el-upload-list__item-status-label{
    display: none !important;
  }
}
</style>
